import { FC, PropsWithChildren, useEffect } from "react";

import { useQuery, useReactiveVar } from "@apollo/client";
import { IStore } from "../../@types/graphql";
import { GET_STORES } from "../../api/graphql/stores";
import { chosenStore } from "../../store/stores.store";
import { MenuBtns } from "../menuBtns";

import "../../styles/components/layouts/workarea.css";

const Header = () => {
  const storeSelected = useReactiveVar(chosenStore);
  const { data, loading } = useQuery<{ stores: IStore[] }>(GET_STORES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => {
      chosenStore(d.stores[0]);
    },
  });

  const stores = data?.stores?.map((i) => ({
    name: i.name,
    value: i.id,
    ...i,
  }));
  const active = storeSelected
    ? { name: storeSelected.name, value: storeSelected.id, ...storeSelected }
    : undefined;

  return (
    <div className="bg-grey900 p-12 mb-24 rounded-8 flex justify-end min-h-[3.5rem]">
      {loading || !stores?.length ? null : (
        <MenuBtns
          elements={stores}
          active={active}
          placeholder="Выберите магазин"
          // @ts-ignore
          handleChange={(i: IStore) => {
            chosenStore(i);
          }}
        />
      )}
    </div>
  );
};

export const Workarea: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="main-workarea">
      <Header />
      {children}
    </div>
  );
};
