import { gql } from "@apollo/client";

export const GET_STORES = gql`
  query Stores {
    stores {
      id
      name
      sellerClientId
      performanceClientId
    }
  }
`;
